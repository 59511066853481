@import url('https://fonts.googleapis.com/css2?family=Lora&family=Yeseva+One&display=swap');

html {
  background: #0a0a0a;
  color: #fff;
}

.main {
  max-width: 960px;
  margin: auto;
  font-family: 'Lora', serif;
}

.logo {
  font-family: 'Yeseva One', serif;
  font-size:60px;
  text-align:center;
  margin-top:1em;
}

.hero {
  text-align: center;
}

.limited-viewing-banner {
  margin:8em;
}

p {
  word-break: break-word;
  margin-top: 2em;
  letter-spacing: -0.003em;
  line-height: 80px;
  font-size: 64px;
  font-weight: 400;
  white-space: pre-wrap;
}
p::selection {
  background: #fb5607;
}

.storyHeader {
  margin-top:8em;
  font-size:20px;
  text-align:center;
}

.storyHeader .readingTime {
  font-size: 16px;
  margin-top: 1em;
}

a {
  color: #fb5607;
  text-decoration: none;
}

.thread_img {
  max-width:100%;
  height:auto;
  display: block;
  margin: 2em auto;
}

.reading-progress-bar {
  position: fixed;
  height: 10px;
  bottom: 0;
  left:0;
  max-width: 100%;
  background-color: #fb5607;
}

h2 {
  line-height: 100px;
  margin-top:2em;
  margin-bottom: 0em;
}

.onboarder {
  margin-top:8em;
  font-size:20px;
}

.onboarder h2 {
  line-height: auto;
  font-family: 'Lora', serif;
  font-size:70px;
}